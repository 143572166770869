import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { useConfig } from '../../common/contexts'
import { Loading } from '../../common/components'

export const StripeContext = createContext<{
  stripe?: Stripe
  setStripeAccount?: React.Dispatch<React.SetStateAction<string | undefined>>
}>({})

export const StripeProvider = ({ children }: { children: JSX.Element | ReactNode }) => {
  const { config } = useConfig()
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const [stripeAccount, setStripeAccount] = useState<string | undefined>()

  useEffect(() => {
    loadStripe(config.stripePublishableKey, { stripeAccount })
      .then((value) => setStripe(value))
      .catch((e) => {
        console.error(e)
        throw e
      })
  }, [config, setStripe, stripeAccount])

  if (!stripe) {
    return <Loading />
  }

  return (
    <StripeContext.Provider
      value={{
        stripe,
        setStripeAccount,
      }}
    >
      {children}
    </StripeContext.Provider>
  )
}

export function useStripe() {
  const { stripe, setStripeAccount } = useContext(StripeContext)

  if (!stripe) {
    throw new Error('stripe is not set')
  }

  return { stripe, setStripeAccount }
}
