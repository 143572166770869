'use client'

import { Grid, Typography } from '@mui/material'
import MuiFormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ErrorMessage } from './ErrorMessage'
import PostalCodeField, { Address } from './PostalCodeField'
import GenderField from './GenderField'
import BirthdayField from './BirthdayField'
import { SelectClinicField } from './SelectClinicField'
import { Logo } from '../../common/components/Logo'
import { usePatientMe } from '../hooks/useGetPatient'
import { useDeps } from '../../common/contexts'

export type FormValue = {
  lastName: string
  firstName: string
  lastNameKana: string
  firstNameKana: string
  birthday: string
  gender: string
  phoneNumber: string
  zipCode: string
  prefecture: string
  city: string
  address1: string
  address2: string
  clinicID: string
  lineUserID?: string
  patientUUID?: string
  parentPatientUUID?: string
}

const FormLabel = styled(MuiFormLabel)<FormLabelProps>(() => ({
  fontWeight: 700,
  fontSize: '14px',
}))

const requiredRules = {
  required: '入力してください',
}

export const kanjiNameRules = {
  ...requiredRules,
  pattern: {
    value: /^[々ぁ-ゖァ-ヺーＡ-Ｚａ-ｚ\u4E00-\u9FAF\uF91D-\uFA6A]+$/g,
    message: '漢字・カタカナ・ひらがなで入力してください',
  },
}
const furiganaNameRules = {
  ...requiredRules,
  pattern: {
    value: /^[ァ-ヶー]+$/,
    message: 'カタカナで入力してください',
  },
}

const phoneNumberRules = {
  ...requiredRules,
  pattern: {
    value: /^[0-9]{10,11}$/,
    message: '数字のみを10または11文字で入力してください',
  },
}

export function RegistrationForm() {
  const [searchParams] = useSearchParams()
  const { api } = useDeps()
  const [disabled, setDisabled] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setError] = useState()

  const methods = useForm<FormValue>({ mode: 'onTouched' })
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid, isDirty },
  } = methods

  const navigate = useNavigate()

  const lineIDToken = searchParams.get('id_token')
  const lineUserID = searchParams.get('line_uid')
  const patientUUID = searchParams.get('patient_uuid')
  const parentPatientUUID = searchParams.get('parent_patient_uuid')

  const patientInfo = usePatientMe(lineIDToken)
  if (!isDirty && patientInfo) {
    setValue('phoneNumber', patientInfo.phoneNumber)
    setValue('zipCode', patientInfo.zipcode)
    setValue('prefecture', patientInfo.prefecture)
    setValue('city', patientInfo.city)
    setValue('address1', patientInfo.address)
  }

  const handlePostalCodeSuccess = (address: Address | null) => {
    if (address) {
      setValue('prefecture', address.pref)
      setValue('city', address.city)
      setValue('address1', address.town)
      trigger('address1')
    }
  }
  const handlePostalCodeError = (message: string | null) => {
    if (message) {
      setValue('prefecture', '')
      setValue('city', '')
    }
  }

  const onSubmit = async (data: FormValue) => {
    setDisabled(true)
    try {
      // post
      await api.createPatient({
        token: lineIDToken || undefined,
        ...data,
      })
      // change next page
      if (searchParams.get('via_check_in')) {
        // the patient came here via check-in
        navigate(`/patients/registration/checked-in?clinic_id=${data.clinicID}`, { state: data })
      } else {
        navigate('/patients/registration/complete', { replace: true })
      }
    } catch (e) {
      console.error(e)
      setError(() => {
        throw e
      })
    } finally {
      setDisabled(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('lineUserID')} value={lineUserID || undefined} />
        <input type="hidden" {...register('patientUUID')} value={patientUUID || undefined} />
        <input
          type="hidden"
          {...register('parentPatientUUID')}
          value={parentPatientUUID || undefined}
        />
        <Grid container direction={'column'} alignItems="center" rowSpacing={0.5} sx={{ my: 1 }}>
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Typography variant="h5">お客様情報の入力</Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Typography>
              今後、保健所への申請や、クリニックからの連絡にこちらの登録情報を利用します。
              <br />
              正確な情報入力のご協力をよろしくお願いします。
            </Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <FormLabel>受診クリニックを選択</FormLabel>
            <ErrorMessage errors={errors} name="clinicID" />
            <SelectClinicField name="clinicID" />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <FormLabel>姓</FormLabel>
            <Typography>例）山田</Typography>
            <ErrorMessage errors={errors} name="lastName" />
            <TextField
              {...register('lastName', kanjiNameRules)}
              error={!!errors.lastName}
              fullWidth
              variant="outlined"
              margin="dense"
              id="lastNameInput"
              inputProps={{
                'aria-label': 'lastName',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>名</FormLabel>
            <Typography>例）太郎</Typography>
            <ErrorMessage errors={errors} name="firstName" />
            <TextField
              {...register('firstName', kanjiNameRules)}
              error={!!errors.firstName}
              fullWidth
              variant="outlined"
              margin="dense"
              id="firstNameInput"
              inputProps={{
                'aria-label': 'firstName',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>姓(カナ)</FormLabel>
            <Typography>例）ヤマダ</Typography>
            <ErrorMessage errors={errors} name="lastNameKana" />
            <TextField
              {...register('lastNameKana', furiganaNameRules)}
              error={!!errors.lastNameKana}
              fullWidth
              variant="outlined"
              margin="dense"
              id="lastNameKanaInput"
              inputProps={{
                'aria-label': 'lastNameKana',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>名(カナ)</FormLabel>
            <Typography>例）タロウ</Typography>
            <ErrorMessage errors={errors} name="firstNameKana" />
            <TextField
              {...register('firstNameKana', furiganaNameRules)}
              error={!!errors.firstNameKana}
              fullWidth
              variant="outlined"
              margin="dense"
              id="firstNameKanaInput"
              inputProps={{
                'aria-label': 'firstNameKana',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>生年月日</FormLabel>
            <Typography>
              例）1986年12月1日の場合 → 19861201
              <br />
              ※数字のみを入力してください
            </Typography>
            <ErrorMessage errors={errors} name="birthday" />
            <BirthdayField name="birthday" />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>性別</FormLabel>
            <Typography>※生物学上の性別にチェックを入れてください</Typography>
            <ErrorMessage errors={errors} name="gender" />
            <GenderField name="gender" />
          </Grid>
          <Grid container item rowSpacing={0}>
            <Grid item xs={12}>
              <FormLabel>電話番号</FormLabel>
              <Typography>
                例）09012341234
                <br />
                ※数字のみを入力してください
              </Typography>
              <ErrorMessage errors={errors} name="phoneNumber" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('phoneNumber', phoneNumberRules)}
                error={!!errors.phoneNumber}
                variant="outlined"
                margin="dense"
                id="phoneNumberInput"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '^[0-9]{10,11}$',
                  'aria-label': 'phoneNumber',
                }}
              />
            </Grid>
          </Grid>
          <Grid container item rowSpacing={0}>
            <Grid item xs={12} sx={{ marginBottom: 1 }}>
              <FormLabel>郵便番号</FormLabel>
              <Typography>
                例)1550033
                <br />
                ※数字のみを入力してください
              </Typography>
              <ErrorMessage errors={errors} name="zipCode" />
            </Grid>
            <Grid item xs={6}>
              <PostalCodeField
                name="zipCode"
                onSuccess={handlePostalCodeSuccess}
                onFailure={handlePostalCodeError}
                variant="outlined"
                margin="dense"
                id="zipCodeInput"
              />
            </Grid>
          </Grid>
          <Grid container item columnSpacing={2}>
            <Grid item xs={6} sx={{ marginBottom: 1 }}>
              <FormLabel>都道府県</FormLabel>
              {/* <Typography>例）東京都</Typography> */}
              {/* <ErrorMessage errors={errors} name="prefecture" /> */}
              <TextField
                {...register('prefecture', requiredRules)}
                inputProps={{
                  readOnly: true,
                  'aria-label': 'prefecture',
                }}
                fullWidth
                variant="outlined"
                margin="dense"
                id="prefectureInput"
              />
            </Grid>
            <Grid item xs={6} sx={{ marginBottom: 1 }}>
              <FormLabel>市区町村</FormLabel>
              {/* <Typography>例）世田谷区</Typography> */}
              {/* <ErrorMessage errors={errors} name="city" /> */}
              <TextField
                {...register('city', requiredRules)}
                inputProps={{
                  readOnly: true,
                  'aria-label': 'city',
                }}
                fullWidth
                variant="outlined"
                margin="dense"
                id="cityInput"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: 1 }}>
            <FormLabel>番地</FormLabel>
            <Typography>例）北沢2丁目24-45</Typography>
            <ErrorMessage errors={errors} name="address1" />
            <TextField
              {...register('address1', {
                ...requiredRules,
              })}
              error={!!errors.address1}
              fullWidth
              variant="outlined"
              margin="dense"
              id="address1Input"
              inputProps={{
                'aria-label': 'address1',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>建物名・部屋番号</FormLabel>
            <Typography>
              例）シモキタフロント201
              <br />
              ※該当しない場合、空欄のままにしてください
            </Typography>
            <ErrorMessage errors={errors} name="address2" />
            <TextField
              {...register('address2')}
              error={!!errors.address2}
              fullWidth
              variant="outlined"
              margin="dense"
              id="address2Input"
              inputProps={{
                'aria-label': 'address2',
              }}
            />
          </Grid>
          <Grid
            container
            item
            style={{
              backgroundColor: '#fbefea',
              margin: '30px calc(50% - 50vw) 0',
              padding: '0 calc(50vw - 50%)',
              width: '100vw',
            }}
          >
            <Grid
              container
              item
              xs={2}
              justifyContent={'flex-end'}
              alignItems={'center'}
              paddingRight={'10px'}
            >
              <InfoIcon color="warning" sx={{ fontSize: 20 }} />
            </Grid>
            <Grid item xs={10} sx={{ py: 2 }}>
              <Typography color="primary" fontWeight={700}>
                今後の連絡にこちらの登録情報を利用します。
                <br />
                正確な情報入力か、今一度ご確認ください。
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pb: 3 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={!isValid || disabled}
                fullWidth
                size="large"
                color="primary"
                sx={{
                  py: 1.5,
                  fontSize: 14,
                  fontWeight: 700,
                  '&.Mui-disabled': {
                    background: '#eaeaea',
                    color: '#fff',
                  },
                }}
              >
                内容を確認したので登録する
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
