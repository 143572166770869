import { format } from 'date-fns'
import { useState } from 'react'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { useElements } from '@stripe/react-stripe-js'
import { useStripe } from '../contexts'
import { Box, Button, Container, FormControl, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../common/components'
import { useReporter } from '../../common/contexts'

type PaymentFormProps = {
  lineIDToken: string
  patientID: string
  clinicID: string
  invoiceNumber: string
  paymentIntent: any
  paymentMethod: any
  onSuccess?: () => void
  onFailure?: () => void
}

export const PaymentForm = ({
  lineIDToken,
  patientID,
  clinicID,
  invoiceNumber,
  paymentIntent,
  paymentMethod,
  onSuccess,
  onFailure,
}: PaymentFormProps) => {
  const { stripe } = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState<boolean>(false)
  const reporter = useReporter()
  const { t } = useTranslation()

  const { card } = paymentMethod

  const expiry = new Date(`${card.expYear}-${('00' + card.expMonth).slice(-2)}-01`)

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    //
    if (!stripe || !elements) {
      console.error('unknown error')
      return
    }

    // show loading indicator
    setLoading(true)

    // NOTE: https://docs.stripe.com/api/payment_intents/confirm?lang=node
    const result = await stripe.confirmCardPayment(
      paymentIntent.clientSecret,
      {
        payment_method: paymentMethod.id,
      },
      // Enagle the default next action handling.
      { handleActions: true }
    )

    // hide loading indicator
    setLoading(false)

    if (result.error) {
      console.error(result.error)
      reporter.reportException(new Error(result.error.message), {
        patientID,
        clinicID,
        invoiceNumber,
        lineIDToken,
      })
      if (onFailure) {
        onFailure()
      }
      return
    }

    if (onSuccess) {
      onSuccess()
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Container sx={{ padding: '2em' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontWeight="bold">{t('Card Number')}</Typography>
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <CreditCardIcon />
                <span style={{ marginLeft: '0.5em' }}>{`**** **** **** ${card.last4}`}</span>
              </p>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontWeight="bold">{t('Expiration')}</Typography>
              <p>{format(expiry, 'MM/yyyy')}</p>
            </FormControl>
          </Grid>

          <Box sx={{ width: '100%' }}>
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              fullWidth
              data-cy="make-payment"
            >
              {t('Make Payment')}
            </Button>
          </Box>
        </Grid>
      </form>
    </Container>
  )
}
