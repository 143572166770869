import { Box, Button, Grid, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import ErrorIcon from '@mui/icons-material/Error'
import { useTranslation } from 'react-i18next'
import { useConfig } from '../../contexts'

type InternalServerErrorProps = {
  error: any
  resetErrorBoundary?: (...args: any[]) => void
}

export function InternalServerError({ error }: InternalServerErrorProps) {
  const { config } = useConfig()
  const { t } = useTranslation()

  console.error(error)

  return (
    <Grid
      container
      direction="row"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid item sx={{ mt: 12, textAlign: 'center' }}>
        <ErrorIcon sx={{ fontSize: 60, color: red[500] }} />
        <Typography variant="h4" fontWeight="bold" sx={{ mt: 2 }}>
          {t('Internal Server Error')}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1">
            {t('The server encountered an error and could not complete your request')}
          </Typography>
        </Box>
        <Box sx={{ py: 3 }}>
          <Button
            href={config.lineChannelURL}
            size="large"
            variant="outlined"
            fullWidth
            sx={{
              borderColor: '#00B900',
              color: '#00B900',
              fontWeight: 700,
              '&:active': {
                borderColor: '#00B900',
              },
              '&:focus': {
                borderColor: '#00B900',
              },
            }}
          >
            LINEに戻る
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
