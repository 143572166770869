import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { StripeElementsOptions } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useStripe } from '../../contexts'
import { Title } from '../../components/Title'
import { PaymentForm } from '../../components/PaymentForm'
import { PaymentTemplate } from '../../templates'
import { useDeps, useFeatureFlag, useReporter } from '../../../common/contexts'
import { Loading } from '../../../common/components'
import { Conflict } from '../../../../clients'

export function PaymentCreatePage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [paymentIntent, setPaymentIntent] = useState<any>(null)
  const [paymentMethod, setPaymentMethod] = useState<any>(null)
  const { stripe, setStripeAccount } = useStripe()

  const deps = useDeps()
  const reporter = useReporter()
  const { loading, featureFlag } = useFeatureFlag()
  const { t } = useTranslation()

  const clinicID = searchParams.get('clinic_id')
  const patientID = searchParams.get('patient_id')
  const invoiceNumber = searchParams.get('invoice_number')
  const lineIDToken = searchParams.get('id_token')

  useEffect(() => {
    if (lineIDToken && clinicID && invoiceNumber && patientID) {
      deps.apiClient
        .createPayment({
          lineIDToken,
          patientID,
          clinicID,
          invoiceNumber,
        })
        .then((res) => {
          setPaymentIntent(res.paymentIntent)
          setPaymentMethod(res.paymentMethod)
          // to create a Stripe instance in order to support Connected Accounts
          if (setStripeAccount) {
            setStripeAccount(res.stripeAccountID)
          }
        })
        .catch((err) => {
          if (err instanceof Conflict) {
            navigate({
              pathname: '/payment/complete',
              search: `?id_token=${lineIDToken}&clinic_id=${clinicID}&invoice_number=${invoiceNumber}&patient_id=${patientID}`,
            })
          } else {
            console.error(err)
            reporter.reportException(err, {
              patientID,
              clinicID,
              invoiceNumber,
              lineIDToken,
            })
            navigate('/payment/failure')
          }
        })
    }
  }, [clinicID, invoiceNumber, lineIDToken, patientID])

  if (!lineIDToken || !clinicID || !invoiceNumber || !patientID) {
    return <Navigate to="/payment/sorry" />
  }

  if (!paymentIntent || !paymentMethod || loading) {
    return <Loading />
  }

  const onSuccess = () => {
    navigate({
      pathname: '/payment/success',
      search: `?id_token=${lineIDToken}&clinic_id=${clinicID}&invoice_number=${invoiceNumber}&patient_id=${patientID}`,
    })
  }

  const onFailure = () => {
    if (featureFlag.isEnabled('selfCheckout', { clinicID })) {
      const errorMessage = '即診の会計に失敗しました'
      navigate({
        pathname: '/payment/self-checkout',
        search: `?id_token=${lineIDToken}&clinic_id=${clinicID}&invoice_number=${invoiceNumber}&patient_id=${patientID}&error=${errorMessage}`,
      })
    } else {
      navigate('/payment/failure')
    }
  }

  const options: StripeElementsOptions = {
    appearance: {
      theme: 'stripe',
    },
    clientSecret: paymentIntent.clientSecret,
    loader: 'auto',
  }

  return (
    <Elements stripe={stripe} options={options}>
      <PaymentTemplate
        title={
          <Title variant="h5" data-cy="title">
            {t('Pay with Credit Card')}
          </Title>
        }
        content={
          <PaymentForm
            lineIDToken={lineIDToken}
            clinicID={clinicID}
            patientID={patientID}
            invoiceNumber={invoiceNumber}
            paymentIntent={paymentIntent}
            paymentMethod={paymentMethod}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
        }
      />
    </Elements>
  )
}
