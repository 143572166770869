'use client'

import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import MuiFormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import { ErrorMessage } from './ErrorMessage'
import GenderField from './GenderField'
import BirthdayField from './BirthdayField'
import { Logo } from '../../common/components/Logo'
import { useDeps, useReporter } from '../../common/contexts'

export type FormValue = {
  formID: string
  lastNameKana: string
  firstNameKana: string
  birthday: string
  gender: string
  phoneNumber: string
}

const FormLabel = styled(MuiFormLabel)<FormLabelProps>(() => ({
  fontWeight: 700,
  fontSize: '14px',
}))

const requiredRules = {
  required: '入力してください',
}

const furiganaNameRules = {
  ...requiredRules,
  pattern: {
    value: /^[ァ-ヶー]+$/,
    message: 'カタカナで入力してください',
  },
}

const phoneNumberRules = {
  ...requiredRules,
  pattern: {
    value: /^[0-9]{10,11}$/,
    message: '数字のみを10または11文字で入力してください',
  },
}

export function TransferToFamilyForm() {
  const search = useLocation().search
  const queryParams = new URLSearchParams(search)
  const { apiClient } = useDeps()
  const reporter = useReporter()

  const location = useLocation()
  const defaultFormValue = location.state as FormValue

  const methods = useForm<FormValue>({ mode: 'onTouched', defaultValues: defaultFormValue })
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = methods

  const navigate = useNavigate()

  const onSubmit = async (data: FormValue) => {
    try {
      const res = await apiClient.requestTransferToFamily({
        lineIDToken: queryParams.get('id_token') || '',
        lastNameKana: data.lastNameKana,
        firstNameKana: data.firstNameKana,
        birthday: data.birthday,
        gender: data.gender,
        phoneNumber: data.phoneNumber,
      })

      if (res.status === 200) {
        navigate('/patients/transfer-to-family/complete', { replace: true, state: data })
      } else {
        navigate('/patients/transfer-to-family/failure?' + queryParams.toString(), {
          replace: true,
          state: data,
        })
      }
    } catch (e) {
      reporter.reportException(e, {
        lineIDToken: queryParams.get('id_token') || '',
        lastNameKana: data.lastNameKana,
        firstNameKana: data.firstNameKana,
        birthday: data.birthday,
        gender: data.gender,
        phoneNumber: data.phoneNumber,
      })
      navigate('/patients/transfer-to-family/failure', { replace: true })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction={'column'} alignItems="center" rowSpacing={0.5} sx={{ my: 1 }}>
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Typography variant="h5">家族を追加する</Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} sx={{ my: 1 }}>
            <Typography>
              すでにクリニックプラスをご利用されたことのある家族アカウントの追加を行います。
              <br />
              <br />
              ※今から追加するアカウントに紐づいている家族アカウントも同時に追加されます。
            </Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <FormLabel>移行対象者の姓(カナ)</FormLabel>
            <Typography>例）ヤマダ</Typography>
            <ErrorMessage errors={errors} name="lastNameKana" />
            <TextField
              {...register('lastNameKana', furiganaNameRules)}
              error={!!errors.lastNameKana}
              fullWidth
              variant="outlined"
              margin="dense"
              id="lastNameKanaInput"
              inputProps={{
                'aria-label': 'lastNameKana',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>移行対象者の名(カナ)</FormLabel>
            <Typography>例）タロウ</Typography>
            <ErrorMessage errors={errors} name="firstNameKana" />
            <TextField
              {...register('firstNameKana', furiganaNameRules)}
              error={!!errors.firstNameKana}
              fullWidth
              variant="outlined"
              margin="dense"
              id="firstNameKanaInput"
              inputProps={{
                'aria-label': 'firstNameKana',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>移行対象者の生年月日</FormLabel>
            <Typography>
              例）1986年12月1日の場合 → 19861201
              <br />
              ※数字のみを入力してください
            </Typography>
            <ErrorMessage errors={errors} name="birthday" />
            <BirthdayField name="birthday" />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>移行対象者の性別</FormLabel>
            <Typography>※生物学上の性別にチェックを入れてください</Typography>
            <ErrorMessage errors={errors} name="gender" />
            <GenderField name="gender" />
          </Grid>
          <Grid container item rowSpacing={0}>
            <Grid item xs={12}>
              <FormLabel>移行対象者の電話番号</FormLabel>
              <Typography color="red" fontWeight="bold">
                移行元のアカウントの番号を入力してください。
              </Typography>
              <Typography>
                例）09012341234
                <br />
                ※移行許諾のショートメッセージを送信します。以前家族アカウントを追加した方の電話番号を入力してください。
              </Typography>
              <ErrorMessage errors={errors} name="phoneNumber" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('phoneNumber', phoneNumberRules)}
                error={!!errors.phoneNumber}
                variant="outlined"
                margin="dense"
                id="phoneNumberInput"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '^[0-9]{10,11}$',
                  'aria-label': 'phoneNumber',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            style={{
              backgroundColor: '#fbefea',
              margin: '30px calc(50% - 50vw) 0',
              padding: '0 calc(50vw - 50%)',
              width: '100vw',
            }}
          >
            <Grid
              container
              item
              xs={2}
              justifyContent={'flex-end'}
              alignItems={'center'}
              paddingRight={'10px'}
            >
              <InfoIcon color="warning" sx={{ fontSize: 20 }} />
            </Grid>
            <Grid item xs={10} sx={{ py: 2 }}>
              <Typography color="primary" fontWeight={700}>
                正確な情報入力か、今一度ご確認ください。
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pb: 3 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={!isValid}
                fullWidth
                size="large"
                color="primary"
                sx={{
                  py: 1.5,
                  fontSize: 14,
                  fontWeight: 700,
                  '&.Mui-disabled': {
                    background: '#eaeaea',
                    color: '#fff',
                  },
                }}
              >
                内容を確認したので登録する
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type="hidden" {...register('formID')} value="patientTransferForm" />
        {
          /* pass all query parameters to this form */
          // @ts-ignore
          (() => {
            const inputs: any = []
            queryParams.forEach((value, key) => {
              inputs.push(
                <input
                  type="hidden"
                  {
                    //@ts-ignore as 'key' here may be undefined in FormValue type
                    ...register(key)
                  }
                  key={`hidden-input-${key}`}
                  name={key}
                  value={value}
                  data-testid={`hidden-input-${key}`}
                />
              )
            })
            return inputs
          })()
        }
      </form>
    </FormProvider>
  )
}
